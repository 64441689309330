body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #000;
  color: #fff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
header {
  text-align: center;
  padding: 50px 0;
  position: relative;
}
h1 {
  font-size: 3em;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
h1::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: glow 2s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    text-shadow: 
      0 0 5px rgba(241, 208, 209, 0.7),
      0 0 10px rgba(241, 208, 209, 0.7),
      0 0 15px rgba(241, 208, 209, 0.7),
      0 0 20px rgba(241, 208, 209, 0.7);
  }
  to {
    text-shadow: 
      0 0 10px rgba(241, 208, 209, 0.85),
      0 0 20px rgba(241, 208, 209, 0.85),
      0 0 30px rgba(241, 208, 209, 0.85),
      0 0 40px rgba(241, 208, 209, 0.85);
  }
}
.projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 20px;
  margin-top: 3vh;
}
.project {
  background: rgba(241, 208, 209, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}
.project:hover {
  transform: scale(1.05);
}
.project h2 {
  color: #f1d0d1;
}
.network-bg {
  width: 100%;
  height: 200px;
  opacity: 0.2;
  position: fixed;
  bottom: 0;
  left: 0;
}
@media (max-width: 768px) {
  .projects {
    grid-template-columns: 1fr;
  }
}
