/* src/Connectivity.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #000;
    color: #fff;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .nodes-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  header {
    text-align: center;
    padding: 50px 0;
    position: relative;
  }
  
  h1 {
    font-size: 3em;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    color: #f1d0d1;
  }
  
  .nodes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .node-card {
    position: relative;
    background: rgba(241, 208, 209, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .node-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .node-content {
    text-align: left;
    padding: 20px;
  }
  
  .redirect-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 2px solid #f1d0d1;
    color: #f1d0d1;
    padding: 5px 10px;
    cursor: pointer;
    text-transform: none;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .redirect-button:hover {
    background-color: #f1d0d1;
    color: #000;
  }
  
  .node-title {
    font-size: 1.5em;
    color: #f1d0d1;
    margin-bottom: 10px;
  }
  
  .node-info {
    font-size: 1em;
    color: #fff;
  }
  
  .node-detail {
    font-weight: bold;
    color: #ccc;
  }
  
  .network-bg {
    width: 100%;
    height: 200px;
    opacity: 0.2;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  
  @media (max-width: 768px) {
    .nodes-grid {
      grid-template-columns: 1fr;
    }
  }
  